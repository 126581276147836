<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Home" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Title"
                  v-model="mainTitle"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.homepage_title"
                  :error-messages="errors.homepage_title"
                  @input="delete errors.homepage_title"
                />
                <va-card class="mb-3" title="Main screen background image *">
                  <div v-if="mainBgImgExisting">
                    <a style="display: block;" target="_blank" :href="mainBgImgExisting"><img style="max-width: 400px;" :src="mainBgImgExisting"></a>
                    <div><va-button small color="danger" @click="mainBgImgExisting = deleteImage(mainBgImgId, 'homepage_main_screen_image') ? '' : mainBgImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.homepage_main_screen_image"
                    v-model="mainBgImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.homepage_main_screen_image" style="color: red;">{{ errors.homepage_main_screen_image[0] }}</div>
                  <va-button @click="uploadImage('homepage_main_screen_image', 'homepage.main.screen.image', mainBgImg[0])">Завантажити</va-button>
                </va-card>

                <va-input
                  label="Video Link *"
                  v-model="mainVideoLink"
                  :error="!!errors.homepage_main_screen_video"
                  :error-messages="errors.homepage_main_screen_video"
                  @input="delete errors.homepage_main_screen_video"
                />
                <va-card class="mb-3" title="Main screen background mobile image *">
                  <div v-if="mainBgImgMobileExisting">
                    <a style="display: block;" target="_blank" :href="mainBgImgMobileExisting"><img style="max-width: 400px;" :src="mainBgImgMobileExisting"></a>
                    <div><va-button small color="danger" @click="mainBgImgMobileExisting = deleteImage(mainBgImgMobileId, 'homepage_main_screen_mobile_image') ? '' : mainBgImgMobileExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.homepage_main_screen_mobile_image"
                    v-model="mainBgImgMobile"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.homepage_main_screen_mobile_image" style="color: red;">{{ errors.homepage_main_screen_mobile_image[0] }}</div>
                  <va-button @click="uploadImage('homepage_main_screen_mobile_image', 'homepage.main.screen.mobile.image', mainBgImgMobile[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Video Mobile Link *"
                  v-model="mainVideoMobile"
                  :error="!!errors.homepage_main_screen_mobile_video"
                  :error-messages="errors.homepage_main_screen_mobile_video"
                  @input="delete errors.homepage_main_screen_mobile_video"
                />
                <va-input
                  label="Scroll title *"
                  v-model="mainScrollTitle"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.homepage_scroll_title"
                  :error-messages="errors.homepage_scroll_title"
                  @input="delete errors.homepage_scroll_title"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Title of service block *"
                  v-model="servicesTitle"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.homepage_service_title"
                  :error-messages="errors.homepage_service_title"
                  @input="delete errors.homepage_service_title"
                />
                <va-input
                  label="Service block button label *"
                  v-model="servicesButtonLabel"
                  :messages="['The recommended number of characters is 20']"
                  :error="!!errors.homepage_service_btn_label"
                  :error-messages="errors.homepage_service_btn_label"
                  @input="delete errors.homepage_service_btn_label"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Video block title *"
                  v-model="videoBlockTitle"
                  :messages="['The recommended number of characters is 120']"
                  :error="!!errors.homepage_video_screen_title"
                  :error-messages="errors.homepage_video_screen_title"
                  @input="delete errors.homepage_video_screen_title"
                />
                <va-card class="mb-3" title="Video block preview image *">
                  <div v-if="videoBlockPreviewImgExisting">
                    <a style="display: block;" target="_blank" :href="videoBlockPreviewImgExisting"><img style="max-width: 400px;" :src="videoBlockPreviewImgExisting"></a>
                    <div><va-button small color="danger" @click="videoBlockPreviewImgExisting = deleteImage(videoBlockPreviewImgId, 'homepage_video_screen_image') ? '' : videoBlockPreviewImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.homepage_video_screen_image"
                    v-model="videoBlockPreviewImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.homepage_video_screen_image" style="color: red;">{{ errors.homepage_video_screen_image[0] }}</div>
                  <va-button @click="uploadImage('homepage_video_screen_image', 'homepage.video.screen.image', videoBlockPreviewImg[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Video block video *"
                  v-model="videoBlockVideo"
                  :messages="['The recommended number of characters is 20']"
                  :error="!!errors.homepage_video_screen_video"
                  :error-messages="errors.homepage_video_screen_video"
                  @input="delete errors.homepage_video_screen_video"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-input
                  label="Capacity block title *"
                  v-model="capacityBlockTitle"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.homepage_capacity_screen_title"
                  :error-messages="errors.homepage_capacity_screen_title"
                  @input="delete errors.homepage_capacity_screen_title"
                />
                <va-input
                  label="Capacity block description *"
                  v-model="capacityBlockDescription"
                  :messages="['The recommended number of characters is 500']"
                  :type="'textarea'"
                  :error="!!errors.homepage_capacity_screen_description"
                  :error-messages="errors.homepage_capacity_screen_description"
                  @input="delete errors.homepage_capacity_screen_description"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 4">
                <va-input
                  label="Title of recycling block *"
                  v-model="recyclingTitle"
                  :messages="['The recommended number of characters is 50']"
                  :error="!!errors.homepage_recycling_title"
                  :error-messages="errors.homepage_recycling_title"
                  @input="delete errors.homepage_recycling_title"
                />
                <va-input
                  label="Description of recycling block *"
                  v-model="recyclingDescription"
                  :messages="['The recommended number of characters is 250']"
                  :type="'textarea'"
                  :error="!!errors.homepage_recycling_description"
                  :error-messages="errors.homepage_recycling_description"
                  @input="delete errors.homepage_recycling_description"
                />
                <va-input
                  label="First recycling item label *"
                  v-model="recyclingFirst"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.homepage_recycling_label_first"
                  :error-messages="errors.homepage_recycling_label_first"
                  @input="delete errors.homepage_recycling_label_first"
                />
                <va-input
                  label="Second recycling item label *"
                  v-model="recyclingSecond"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.homepage_recycling_label_second"
                  :error-messages="errors.homepage_recycling_label_second"
                  @input="delete errors.homepage_recycling_label_second"
                />
                <va-input
                  label="Third recycling item label *"
                  v-model="recyclingThird"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.homepage_recycling_label_third"
                  :error-messages="errors.homepage_recycling_label_third"
                  @input="delete errors.homepage_recycling_label_third"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 5">
                <va-input
                  label="Quality block title *"
                  v-model="qualityTitle"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.homepage_quality_title"
                  :error-messages="errors.homepage_quality_title"
                  @input="delete errors.homepage_quality_title"
                />
                <va-input
                  label="Quality block description *"
                  v-model="qualityDescription"
                  :messages="['The recommended number of characters is 300']"
                  :type="'textarea'"
                  :error="!!errors.homepage_quality_description"
                  :error-messages="errors.homepage_quality_description"
                  @input="delete errors.homepage_quality_description"
                />
                <va-input
                  label="Quality block stage title *"
                  v-model="qualityStageTitle"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.homepage_quality_stage_title"
                  :error-messages="errors.homepage_quality_stage_title"
                  @input="delete errors.homepage_quality_stage_title"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 6">
                <va-input
                  label="Technics block title *"
                  v-model="technicsTitle"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.homepage_technics_screen_title"
                  :error-messages="errors.homepage_technics_screen_title"
                  @input="delete errors.homepage_technics_screen_title"
                />
                <va-input
                  label="Technics block description *"
                  v-model="technicsDescription"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 300']"
                  :error="!!errors.homepage_technics_screen_description"
                  :error-messages="errors.homepage_technics_screen_description"
                  @input="delete errors.homepage_technics_screen_description"
                />
                <va-card class="mb-3" title="Technics screen first image *">
                  <div v-if="technicsFirstImgExisting">
                    <a style="display: block;" target="_blank" :href="technicsFirstImgExisting"><img style="max-width: 400px;" :src="technicsFirstImgExisting"></a>
                    <div><va-button small color="danger" @click="technicsFirstImgExisting = deleteImage(technicsFirstImgId, 'homepage_technics_screen_first_image') ? '' : technicsFirstImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.homepage_technics_screen_first_image"
                    v-model="technicsFirstImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.homepage_technics_screen_first_image" style="color: red;">{{ errors.homepage_technics_screen_first_image[0] }}</div>
                  <va-button @click="uploadImage('homepage_technics_screen_first_image', 'homepage.technics.screen.first.image', technicsFirstImg[0])">Завантажити</va-button>
                </va-card>

                <va-card class="mb-3" title="Technics screen first image *">
                  <div v-if="technicsSecondImgExisting">
                    <a style="display: block;" target="_blank" :href="technicsSecondImgExisting"><img style="max-width: 400px;" :src="technicsSecondImgExisting"></a>
                    <div><va-button small color="danger" @click="technicsSecondImgExisting = deleteImage(technicsSecondImgId, 'homepage_technics_screen_second_image') ? '' : technicsSecondImgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.homepage_technics_screen_second_image"
                    v-model="technicsSecondImg"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.homepage_technics_screen_second_image" style="color: red;">{{ errors.homepage_technics_screen_second_image[0] }}</div>
                  <va-button @click="uploadImage('homepage_technics_screen_second_image', 'homepage.technics.screen.second.image', technicsSecondImg[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Technics block button label *"
                  v-model="technicsButton"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.homepage_technics_btn_label"
                  :error-messages="errors.homepage_technics_btn_label"
                  @input="delete errors.homepage_technics_btn_label"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 7">
                <va-input
                  label="Team block title*"
                  v-model="teamTitle"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.homepage_team_screen_title"
                  :error-messages="errors.homepage_team_screen_title"
                  @input="delete errors.homepage_team_screen_title"
                />
                <va-input
                  label="Team block first content *"
                  v-model="teamFirst"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 300']"
                  :error="!!errors.homepage_team_screen_first_content"
                  :error-messages="errors.homepage_team_screen_first_content"
                  @input="delete errors.homepage_team_screen_first_content"
                />
                <va-input
                  label="Team block second content *"
                  v-model="teamSecond"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 300']"
                  :error="!!errors.homepage_team_screen_second_content"
                  :error-messages="errors.homepage_team_screen_second_content"
                  @input="delete errors.homepage_team_screen_second_content"
                />
                <va-input
                  label="Team block button label *"
                  v-model="teamButton"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.homepage_team_btn_label"
                  :error-messages="errors.homepage_team_btn_label"
                  @input="delete errors.homepage_team_btn_label"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      mainTitle: '',
      mainBgImgExisting: '',
      mainBgImg: [],
      mainBgImgId: '',
      mainBgImgMobile: [],
      mainBgImgMobileId: '',
      mainBgImgMobileExisting: '',
      mainVideoLink: '',
      mainVideoMobile: '',
      mainScrollTitle: '',

      servicesTitle: '',
      servicesButtonLabel: '',

      videoBlockTitle: '',
      videoBlockPreviewImg: [],
      videoBlockPreviewImgId: '',
      videoBlockPreviewImgExisting: '',
      videoBlockVideo: '',

      capacityBlockTitle: '',
      capacityBlockDescription: '',

      recyclingTitle: '',
      recyclingDescription: '',
      recyclingFirst: '',
      recyclingSecond: '',
      recyclingThird: '',

      qualityTitle: '',
      qualityDescription: '',
      qualityStageTitle: '',

      technicsTitle: '',
      technicsDescription: '',
      technicsFirstImg: [],
      technicsFirstImgId: '',
      technicsFirstImgExisting: '',
      technicsSecondImg: [],
      technicsSecondImgId: '',
      technicsSecondImgExisting: '',
      technicsButton: '',

      teamTitle: '',
      teamFirst: '',
      teamSecond: '',
      teamButton: '',

      errors: [],

      tabTitles: ['Main', 'Services', 'Video', 'Production capacity', 'Recycling', 'Quality', 'Technics', 'Team'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        homepage_title: this.mainTitle,
        homepage_main_screen_video: this.mainVideoLink,
        homepage_main_screen_mobile_video: this.mainVideoMobile,
        homepage_scroll_title: this.mainScrollTitle,

        homepage_service_title: this.servicesTitle,
        homepage_service_btn_label: this.servicesButtonLabel,

        homepage_video_screen_title: this.videoBlockTitle,
        homepage_video_screen_video: this.videoBlockVideo,

        homepage_capacity_screen_title: this.capacityBlockTitle,
        homepage_capacity_screen_description: this.capacityBlockDescription,

        homepage_recycling_title: this.recyclingTitle,
        homepage_recycling_description: this.recyclingDescription,
        homepage_recycling_label_first: this.recyclingFirst,
        homepage_recycling_label_second: this.recyclingSecond,
        homepage_recycling_label_third: this.recyclingThird,

        homepage_quality_title: this.qualityTitle,
        homepage_quality_description: this.qualityDescription,
        homepage_quality_stage_title: this.qualityStageTitle,

        homepage_technics_screen_title: this.technicsTitle,
        homepage_technics_screen_description: this.technicsDescription,
        homepage_technics_btn_label: this.technicsButton,

        homepage_team_screen_title: this.teamTitle,
        homepage_team_screen_first_content: this.teamFirst,
        homepage_team_screen_second_content: this.teamSecond,
        homepage_team_btn_label: this.teamButton,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/upload/10`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/10/delete-file/${id}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.homepage_title || !!this.errors.homepage_main_screen_video || !!this.errors.homepage_main_screen_mobile_video ||
          !!this.errors.homepage_scroll_title || !!this.errors.homepage_main_screen_image || !!this.errors.homepage_main_screen_mobile_image
      } else if (tab === 1) {
        return !!this.errors.homepage_service_title || !!this.errors.homepage_service_btn_label
      } else if (tab === 2) {
        return !!this.errors.homepage_video_screen_title || !!this.errors.homepage_video_screen_video || !!this.errors.homepage_video_screen_image
      } else if (tab === 3) {
        return !!this.errors.homepage_capacity_screen_title || !!this.errors.homepage_capacity_screen_description
      } else if (tab === 4) {
        return !!this.errors.homepage_recycling_title || !!this.errors.homepage_recycling_description ||
          !!this.errors.homepage_recycling_label_first || !!this.errors.homepage_recycling_label_second || !!this.errors.homepage_recycling_label_third
      } else if (tab === 5) {
        return !!this.errors.homepage_quality_title || !!this.errors.homepage_quality_description || !!this.errors.homepage_quality_stage_title
      } else if (tab === 6) {
        return !!this.errors.homepage_technics_screen_title || !!this.errors.homepage_technics_screen_description || !!this.errors.homepage_technics_btn_label ||
          this.errors.homepage_technics_screen_first_image || !!this.errors.homepage_technics_screen_second_image
      } else if (tab === 7) {
        return !!this.errors.homepage_team_screen_title || !!this.errors.homepage_team_screen_first_content || !!this.errors.homepage_team_screen_second_content || !!this.errors.homepage_team_btn_label
      }
      return false
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/10`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/validate/10`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/10`)
        .then(response => {
          // console.log(response.data)
          this.mainTitle = response.data.homepage_title
          this.mainVideoLink = response.data.homepage_main_screen_video
          this.mainVideoMobile = response.data.homepage_main_screen_mobile_video
          this.mainScrollTitle = response.data.homepage_scroll_title

          this.servicesTitle = response.data.homepage_service_title
          this.servicesButtonLabel = response.data.homepage_service_btn_label

          this.videoBlockTitle = response.data.homepage_video_screen_title
          this.videoBlockVideo = response.data.homepage_video_screen_video

          this.capacityBlockTitle = response.data.homepage_capacity_screen_title
          this.capacityBlockDescription = response.data.homepage_capacity_screen_description

          this.recyclingTitle = response.data.homepage_recycling_title
          this.recyclingDescription = response.data.homepage_recycling_description
          this.recyclingFirst = response.data.homepage_recycling_label_first
          this.recyclingSecond = response.data.homepage_recycling_label_second
          this.recyclingThird = response.data.homepage_recycling_label_third

          this.qualityTitle = response.data.homepage_quality_title
          this.qualityDescription = response.data.homepage_quality_description
          this.qualityStageTitle = response.data.homepage_quality_stage_title

          this.technicsTitle = response.data.homepage_technics_screen_title
          this.technicsDescription = response.data.homepage_technics_screen_description
          this.technicsButton = response.data.homepage_technics_btn_label

          this.teamTitle = response.data.homepage_team_screen_title
          this.teamFirst = response.data.homepage_team_screen_first_content
          this.teamSecond = response.data.homepage_team_screen_second_content
          this.teamButton = response.data.homepage_team_btn_label

          this.mainBgImgExisting = response.data.homepage_main_screen_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.homepage_main_screen_image : ''
          this.mainBgImgMobileExisting = response.data.homepage_main_screen_mobile_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.homepage_main_screen_mobile_image : ''
          this.videoBlockPreviewImgExisting = response.data.homepage_video_screen_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.homepage_video_screen_image : ''
          this.technicsFirstImgExisting = response.data.homepage_technics_screen_first_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.homepage_technics_screen_first_image : ''
          this.technicsSecondImgExisting = response.data.homepage_technics_screen_second_image ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.homepage_technics_screen_second_image : ''
          this.mainBgImgId = response.data.homepage_main_screen_image_id
          this.mainBgImgMobileId = response.data.homepage_main_screen_mobile_image_id
          this.videoBlockPreviewImgId = response.data.homepage_video_screen_image_id
          this.technicsFirstImgId = response.data.homepage_technics_screen_first_image_id
          this.technicsSecondImgId = response.data.homepage_technics_screen_second_image_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
